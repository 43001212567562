
.hero-wrap {
  width: 100%;
  height: 100%;
  position: inherit;
  background-size: cover;
  background-repeat: no-repeat; }
  .hero-wrap .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    opacity: .1;
    width: 50%;
    z-index: -1;
    background: #3e64ff; }
  .hero-wrap .text {
    width: 100%; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; 
}
  
@media (min-width: 576px) {
  .container {
    max-width: 540px; } }
@media (min-width: 768px) {
  .container {
    max-width: 720px; } }
@media (min-width: 992px) {
  .container {
    max-width: 960px; } }
@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.navbar-brand {
  font-weight: 900;
  font-size: 26px;
  position: absolute;
  left: 20vw;
  top: 3vh;
  z-index: 0; }
  .navbar-brand:after {
    position: absolute;
    top: 50%;
    left: -12px;
    width: 40px;
    height: 40px;
    content: '';
    background: #3e64ff;
    z-index: -1;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%; }
  .navbar-brand span {
    color: #fff; }
