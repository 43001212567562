.code-container {
    width: 50vw ;
    height: 70vh ;
    background-color: rgb(40,41,35);
    margin-top: 20vh;
    margin-left: 25vw;
    border-radius: 4px;
    padding: 8px;
    opacity: 0.9;
}

.code-content {
    font-size: 20px;
    margin-left: 5px;
    margin-top: 10px;
    line-height: 30px;
    font-style: italic;
    color: thistle;
}