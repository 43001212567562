.text {
    font-size: 5vh;
    position: absolute;
    z-index: 2;
    text-align: center;
    font-weight: 900;
    top: 30vh;
}


.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}
 
@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
